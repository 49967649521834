.IconCorporationLogo {
  width: 190px;
  height: 50px;
  background: url(../images/logo_kubota.svg) 0 0 no-repeat;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  line-height: 1;
}

.Header {
  width: 100%;
  border-top: 3px solid #00a8a9;
  border-bottom: 1px solid #dedede;
  background-color: #fff;
  padding-top: 20px;
  padding-left: 20px;
  padding-bottom: 5px;
}

@media (max-width: 600px) {
  .Header {
    padding: 10px 5px 0 0;
  }
}

.Header__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  max-width: 1600px;
  margin: 0 auto;
  padding: 20px 28px 18px;
}

.HeaderLogo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.HeaderLogo__img {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.HeaderLogo__text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 0;
  margin-left: 20px;
  padding-left: 18px;
  color: #666;
  font-size: 12px;
  font-weight: bold;
  vertical-align: middle;
  position: relative;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
