body {
  background-color: #F6F6F6;
  height: 100%;
}
#root {
  height: 100%;
}
header {
  background-color: #FFF;
  border-bottom: solid 1px #eee;
}
.rs-content {
  padding-top: 15px;
}
.rs-nav-bar {
  border-top: none!important;
}
.rs-panel {
  background-color: #fff;
  border-radius: 0;
}

.rs-table-cell-content {
  white-space: normal;
}

.rs-panel-header {
  border-bottom: solid 1px #ddd;
  margin-bottom: 30px;
  font-weight: bold;
}

.rs-carousel {
  height: auto!important;
}

.rs-drawer-header {
  padding: 20px;
}

.selector-drawer-footer {
  padding-top: 20px;
  background-color: white;
  margin: 0;
}


.rs-breadcrumb {
  font-size: 16px;
  margin-bottom: 3px;
  margin-left: 6px;
  .rs-breadcrumb-item {
    color: gray;
  }
}

.rs-tree {
  overflow: auto!important;
}
.rs-tree-node {
  margin: 0;
}

.rs-tree-node-children.rs-tree-open + .rs-tree-node-children{
  border-top: 1px solid #ccc;
}

.rs-tree-node[aria-expanded="true"] {
  border-bottom: none!important;
}

.pc-list-item {
  /*border-bottom: 1px solid #ccc;*/
  padding: 15px;
  width: 100%;
}

.rs-tree-nodes > .rs-tree-node-children > .rs-tree-node:first-child >.rs-tree-node-label >.rs-tree-node-label-content .pc-list-item:before {
  border-bottom: none!important;
}

.pc-list-item:before {
  content: '';
  display: inline-block;
  width: 12px;
  height: 1px;
  border-bottom: 1px solid #ccc;
  position: absolute;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
}

.rs-tree-node:has(>.rs-tree-node-label>.rs-tree-node-label-content>.pc-list-item.pc-list-item-active) {
  background-color: #00A9AF21;
}

.rs-tree-node-label-content.rs-tree-node-label-content-focus, .rs-tree-node-label-content:focus, .rs-tree-node-label-content:hover {
  background-color: transparent!important;
}

.rs-tree-node-active .rs-tree-node-label-content {
  background-color: transparent!important;
}

.rs-tree-nodes > .rs-tree-node-children > .rs-tree-node:first-child {
  padding-left: 0!important;
  border-bottom: 1px solid #ccc;
}

.rs-tree-node-label {
  padding-left: 0;
  width: 100%;
}

.rs-tree-indent-line {
  height: 100%;
  width: 1px;
  position: absolute;
  top: 0;
  margin-left: 0;
  border-left: 1px solid #ccc;
}

.rs-tree-node-label-content {
  outline: none;
  padding: 0;
  border: none;
  font-weight: normal!important;
  width: 100%;
}

.rs-tree-node-expand-icon-wrapper {
  padding: 10px;
  right: 0;
}

.rs-content:has(>.rs-grid-container>.intro_screen) {
  padding: 0;
}

.rs-grid-container:has(>.intro_screen) {
  width: 100%;
  padding: 0;
}
.carousel-root>.carousel-slider>.control-dots>.dot {
  width: 12px;
  height: 12px;
}

.image-carousel>.carousel-slider>.carousel-status {
  top: 0;
  left: 48%;
  right: unset;
  font-size: 12px;
}

.ssdb-carousel>.carousel-slider>.control-dots>.dot {
  width: 15px;
  height: 15px;
  background: #009999;
}
.ssdb-carousel>.carousel-slider>.control-arrow {
  padding: 0!important;
  background: rgba(0,0,0,0.2) !important;
}
.ssdb-carousel>.carousel-slider>.control-arrow:hover {
  padding: 8px!important;
}
.ssdb-carousel>.carousel-slider>.carousel-status {
  top: unset;
  bottom: 0;
  left: 46%;
  font-size: 20px;
  color: #009999;
  text-shadow: none;
  font-weight: bold;
}

/* SliderCustomStyles.css */

/* Custom left arrow */
.slick-prev:before {
  color: #009999; /* Change the color to black or any other color that is visible on a white background */
  font-size: 25px; /* Adjust the size as needed */
}

.slick-prev {
  left: -20px;
}

/* Custom right arrow */
.slick-next:before {
  color: #009999; /* Change the color to black or any other color that is visible on a white background */
  font-size: 25px; /* Adjust the size as needed */
}

.slick-next {
  right: -16px;
}

.slick-slide {
  display: flex!important;
  justify-content: center!important;
}

.slick-track {
  display: flex!important;
  align-items: baseline;
}
